body{
    background-color: #171421;
    color: #D0CFCC;
    font-family: monospace;
    font-size: medium;
    padding: auto;
    line-height: 1.6;
    
}
label{
    padding: auto;
    color: #33C057;
    font-family: monospace;
    font-size: medium;
    font-weight: bold;
}
input[type=text]{
    border: none;
    background-color: #171421;
    color: #D0CFCC;
    /* width: 80%; */
    font-family: monospace;
    font-size: medium;
    caret-shape: un;
    caret-color: #33C057;
}
*:focus{
    outline: none;
}
a:link {
    color: #33C057;
  }
  #bb{
    margin: 1%;
    padding: 1%;
    box-sizing: border-box;
width: 98%;
height: 100%;
min-height: 100dvh;
border:2px solid #33C057;
    border-radius: 5px;
  }
/* html{
    margin: 1%;
    padding: 1%;
    height: 100dvh;
    border:2px solid #33C057;
    border-radius: 5px;
    
} */
::selection {
    color: #171421;
    background: #33C057;
  }
#PC{
    width: 80%;
    border-radius: 8px;
    height: auto;
    margin: 1%;
}
#Mobile{
    width: 80%;
    border-radius: 8px;
    height: auto;
}
#l{
    align-items: center;
    margin-left: 15%;
}

@media screen and (max-width: 500px) {
    .flex-container {
      flex-direction: column;
    }
  }
  .flex-container {
    display: flex;
    flex-direction: row;
  }
  .flex-item-left{
    text-align: justify;
    flex: 80%;

  }
  .flex-item-right{
    align-content: center;
    align-items: center;
    margin-left: 3%;
    flex: 20%;

  }
